<template>
    <localization :language="'ru-RU'">
        <intl :locale="'ru'">
            <div class="request-management">
                <CRow class="table-actions-row">
                    <v-col sm="12" cols="12" lg="4" class="pl-0">
                        <breadcrumbs :items="crumbs" />
                    </v-col>
                    <v-col sm="12" md="12" lg="8"></v-col>
                </CRow>
                <Grid
                    ref="grid"
                    class="request"
                    ::resizable="true"
                    :sortable="true"
                    :filterable="true"
                    :pageable="gridPageable"
                    :filter="filter"
                    @datastatechange="dataStateChange"
                    :take="take"
                    :skip="skip"
                    :sort="sort"
                    :page-size="pageSize"
                    :total="total"
                    :data-items="dataResult"
                    :columns="columns"
                    @sortchange="sortChange"
                >
                    <template v-slot:have_questions_filter>
                        <boolean-filter
                            @change="
                                selectFilter(
                                    'have_questions',
                                    $event.value.value
                                )
                            "
                        />
                    </template>
                    <template v-slot:date_filter="{ methods, props }">
                        <div class="d-flex align-end custom">
                            <datepicker
                                :default-show="defaultShow"
                                :value="value"
                                @change="dateChangeHandler"
                            />
                            <button
                                @click="clearDates"
                                title="Clear"
                                type="button"
                                class="k-button k-button-icon k-clear-button-visible"
                            >
                                <span class="k-icon k-i-filter-clear"></span>
                            </button>
                        </div>
                    </template>
                    <template v-slot:have_lunch_filter>
                        <boolean-filter
                            @change="
                                selectFilter('have_lunch', $event.value.value)
                            "
                        />
                    </template>
                    <template v-slot:have_questions="{ props: { dataItem } }">
                        <td>
                            <span
                                class="m-badge m-badge--wide m-badge--wide"
                                :class="{
                                    'm-badge--success': dataItem.have_questions,
                                    'm-badge--danger': !dataItem.have_questions,
                                }"
                            >
                                {{ dataItem.have_questions ? 'ДА' : 'НЕТ' }}
                            </span>
                        </td>
                    </template>
                    <template v-slot:have_lunch="{ props: { dataItem } }">
                        <td>
                            <span
                                class="m-badge m-badge--wide m-badge--wide"
                                :class="{
                                    'm-badge--success': dataItem.have_lunch,
                                    'm-badge--danger': !dataItem.have_lunch,
                                }"
                            >
                                {{ dataItem.have_lunch ? 'ДА' : 'НЕТ' }}
                            </span>
                        </td>
                    </template>
                    <template v-slot:basic_cost="{ props: { dataItem } }">
                        <td>
                            {{ dataItem.basic_cost | fractionSizeSpace }}
                        </td>
                    </template>
                    <template v-slot:full_cost="{ props: { dataItem } }">
                        <td>
                            {{ dataItem.full_cost | fractionSizeSpace }}
                        </td>
                    </template>
                    <template v-slot:status_filter>
                        <boolean-filter
                            @change="selectFilter('status', $event.value.value)"
                        />
                    </template>
                    <template v-slot:status="{ props: { dataItem } }">
                        <td>
                            {{ dataItem.status_name }}
                        </td>
                    </template>
                </Grid>
                <v-row class="mt-3 mb-2">
                    <v-col cols="12" class="pl-0 d-flex justify-end">
                        <CButton
                            @click="downloadReport"
                            class="export-excel-btn"
                            color="success"
                        >
                            <i class="mr-2 fa fa-save"></i
                            ><span>Экспорт в Excel</span>
                        </CButton>
                    </v-col>
                </v-row>
            </div>
        </intl>
    </localization>
</template>

<script>
import api from '@/api';
import { mapActions, mapGetters } from 'vuex';
import { Grid } from '@progress/kendo-vue-grid';
import {
    IntlProvider,
    LocalizationProvider,
} from '@progress/kendo-vue-intl';
import '@/views/grid_elements/translations';
import TableMixin from '@/views/grid_elements/mixins/TableMixin';
import ResizableTableMixin from '@/views/grid_elements/mixins/ResizableTableMixin';
import Breadcrumbs from '@/views/components/breadcrumbs';
import BooleanFilter from '@/views/components/BooleanFilter';
import { DatePicker } from '@progress/kendo-vue-dateinputs';
import { toDataSourceRequestString } from '@progress/kendo-data-query';

export default {
    name: 'request-statistics',
    components: {
        localization: LocalizationProvider,
        intl: IntlProvider,
        Grid: Grid,
        breadcrumbs: Breadcrumbs,
        datepicker: DatePicker,
        BooleanFilter,
    },
    mixins: [TableMixin, ResizableTableMixin],
    data() {
        return {
            skip: 0,
            take: 10,
            pageSize: 10,
            total: 7,
            gridPageable: { pageSizes: true },
            defaultShow: false,
            value: null,
            columns: [
                {
                    field: 'number',
                    title: 'Номер заказа',
                    type: 'text',
                    filter: 'text',
                    minWidth: 160,
                    width: '160px',
                },
                {
                    field: 'created_at',
                    title: 'Дата создания заявки',
                    type: 'date',
                    filter: 'date',
                    format: '{0:d}',
                    minWidth: 220,
                    width: '220px',
                    filterCell: 'date_filter'
                },
                {
                    field: 'dealer_name',
                    title: 'Дилер',
                    type: 'text',
                    minWidth: 250,
                    width: '250px',
                },
                {
                    field: 'unique_id',
                    title: 'КССС',
                    type: 'text',
                    filter: 'text',
                    minWidth: 160,
                    width: '160px',
                },
                {
                    field: 'visit_at',
                    title: 'Дата поездки',
                    type: 'date',
                    filter: 'date',
                    format: '{0:d}',
                    minWidth: 250,
                    width: '250px',
                },
                {
                    field: 'tpp_name',
                    title: 'Объект',
                    type: 'text',
                    minWidth: 250,
                    width: '250px',
                },
                {
                    field: 'user_count',
                    title: 'Количество участников',
                    type: 'numeric',
                    filter: 'numeric',
                    minWidth: 200,
                    width: '200px',
                },
                {
                    field: 'have_questions',
                    title: 'Нужна ли сессия вопросы/ответы?',
                    type: 'boolean',
                    cell: 'have_questions',
                    filterCell: 'have_questions_filter',
                    filter: 'boolean',
                    minWidth: 300,
                    width: '300px',
                },
                {
                    field: 'have_lunch',
                    title: 'Нужен ли обед?',
                    type: 'boolean',
                    cell: 'have_lunch',
                    filterCell: 'have_lunch_filter',
                    filter: 'boolean',
                    minWidth: 300,
                    width: '300px',
                },
                // {
                //     field: 'basic_cost',
                //     cell: 'basic_cost',
                //     title: 'Базовая стоимость',
                //     type: 'numeric',
                //     filter: 'numeric',
                //     minWidth: 200,
                //     width: '200px',
                // },
                // {
                //     field: 'full_cost',
                //     cell: 'full_cost',
                //     title: 'Общая сумма заказа',
                //     type: 'numeric',
                //     filter: 'numeric',
                //     minWidth: 200,
                //     width: '200px',
                // },
                {
                    field: 'status',
                    title: 'Статус',
                    cell: 'status',
                    filterCell: 'status_filter',
                    minWidth: 160,
                    width: '160px',
                },
            ],
        };
    },
    mounted() {
        if (this.isCurrentUserAdministrator) {
            this.fetchAgencyListShort();
        }

        this.fetchDealerListShort();
        this.getData();
    },
    computed: {
        ...mapGetters('dealer', ['dealersListShort']),
        dealerFilter() {
            return [
                {
                    id: null,
                    name: 'Все',
                },
                ...this.dealersListShort,
            ];
        },
    },
    methods: {
        fetchData: api.agent_organizer.getVisitOrdersStatistics,
        ...mapActions('dealer', ['fetchDealerListShort']),
        selectDealer({ value }) {
            if (value.id) {
                this.setFilter('dealer_id', 'eq', value.id);
            } else {
                this.removeFilter('dealer_id');
            }

            this.updateTable(true);
        },
        prepareFetchParams() {
            return [
                this.page,
                this.take,
                toDataSourceRequestString({
                    filter: this.filter,
                    sort: this.sort,
                }),
            ];
        },
        dateChangeHandler({value}) {
            this.value = value
            this.removeFilter('date');
            this.setFilter('created_at','contains', this.value);
            this.updateTable(true);
        },
        clearDates() {
            this.value = null
            this.removeFilter('created_at')
            this.updateTable(true);
        },
        async downloadReport() {
            try {
                const response =
                    await api.agent_organizer.getVisitOrdersStatisticsReport(
                        toDataSourceRequestString({
                            filter: this.filter,
                            sort: this.sort,
                        })
                    );

                const fileURL = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', `Заказы.xlsx`);
                document.body.appendChild(fileLink);

                fileLink.click();
            } catch (e) {
                console.warn(e);
            }
        },
    },
};
</script>
