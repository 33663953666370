<template>
<dropdownlist class="boolean-filter" v-on="$listeners" v-bind="{...$props, ...$attrs}" :text-field="'text'" :data-item-key="'value'" :default-item="{value: null, text: 'Все'}" :data-items="items">
</dropdownlist>
</template>

<script>
import {DropDownList} from '@progress/kendo-vue-dropdowns';

export default {
  name: "BooleanFilter",
  components: {
    'dropdownlist': DropDownList
  },
  data() {
    return {
      items: [
        {
          text: 'Да',
          value: true
        },
        {
          text: 'Нет',
          value: false
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>